import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";
import Footer from "root/sections/Footer";
import GetStartedSuccessHero from "root/sections/GetStartedSuccessHero";

import styles from "./success.module.css";

const SuccessPage = ({ location }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div>
      <SEO title="Drivit | Get Started" />
      <Layout>
        <Header currentPage="success" background="darkGreen" />
        {mounted ? (
          <GetStartedSuccessHero calendlySearch={location.search} />
        ) : (
          <div className={styles.placeholder} />
        )}
        <Footer />
      </Layout>
    </div>
  );
};

SuccessPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default SuccessPage;
