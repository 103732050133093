import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import Button from "root/components/Button";

import styles from "./index.module.css";

const GetStartedSuccessHero = ({ calendlySearch }) => {
  return (
    <Section color="darkGreen">
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.text}>
            <Typography variant="h2" color="white" weight="bold">
              Welcome!
            </Typography>
            <div className={styles.midText}>
              <Typography color="white">
                Thank you for reaching out, we’ll be in touch
              </Typography>
            </div>
            <Typography variant="h2" color="white" weight="bold">
              Do you want to schedule a call?
            </Typography>
          </div>
          <div className={styles.scheduleButton}>
            <a
              href={`https://calendly.com/goncalo-farinha${calendlySearch}`}
              rel="noopener noreferrer"
              target="_blank"
              className={styles.firstButton}
            >
              <Button color="green" size="large">
                <Typography color="white" weight="bold">
                  Schedule a call
                </Typography>
              </Button>
            </a>
            <div className={styles.secondButton}>
              <Link to="/">
                <Button color="whiteBorderGreen" size="large">
                  <Typography color="white" weight="bold">
                    Maybe Later
                  </Typography>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

GetStartedSuccessHero.propTypes = {
  calendlySearch: PropTypes.string,
};

GetStartedSuccessHero.defaultProps = {
  calendlySearch: "",
};

export default GetStartedSuccessHero;
